/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactElement, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Layouts } from "@container";
import { Info01, Info02, Info03, Info04, Info05 } from "./Info";

const MedicalInfoContents = ({ location }): ReactElement => {
  const pathname = location.pathname;

  const [showItem1, setShowItem1] = useState(false);
  const [showItem2, setShowItem2] = useState(false);
  const [showItem3, setShowItem3] = useState(false);
  const [showItem4, setShowItem4] = useState(false);
  const [showItem5, setShowItem5] = useState(false);

  const renderMedicalInfoItems = () => {
    if (pathname === "/medicalinfo/info/1") {
      setShowItem1(true);
    } else {
      setShowItem1(false);
    }

    if (pathname === "/medicalinfo/info/2") {
      setShowItem2(true);
    } else {
      setShowItem2(false);
    }

    if (pathname === "/medicalinfo/info/3") {
      setShowItem3(true);
    } else {
      setShowItem3(false);
    }

    if (pathname === "/medicalinfo/info/4") {
      setShowItem4(true);
    } else {
      setShowItem4(false);
    }

    if (pathname === "/medicalinfo/info/5") {
      setShowItem5(true);
    } else {
      setShowItem5(false);
    }
  };

  useEffect(() => {
    renderMedicalInfoItems();
  });

  return (
    <Layouts>
      <section className="medical-info">
        {(showItem1 && <Info01 />) ||
          (showItem2 && <Info02 />) ||
          (showItem3 && <Info03 />) ||
          (showItem4 && <Info04 />) ||
          (showItem5 && <Info05 />)}
      </section>
    </Layouts>
  );
};

export default withRouter(MedicalInfoContents);
