import React, { useState } from "react";
import shortid from "shortid";
import { InfoSlider } from "@presentational";
import {
  info07,
  info07Cause,
  info07Cure,
  info07Process,
  info07DiseaseResource,
  info07GuideResource,
  info07Rule,
  info07Symptom,
  info07Tab,
  info07Source,
} from "../configs";

const Info07 = () => {
  const [sliderCount, setSliderCount] = useState<any>();
  const [currentSlider, setCurrentSlider] = useState<any>();

  function SliderPaging(i) {
    const pageCount = document.querySelector(".slick-dots")?.childElementCount;
    setCurrentSlider(pageCount);
    return (
      <p>
        <span>{i + 1} </span>/ {currentSlider}
      </p>
    );
  }

  function MobileSliderPaging(i) {
    const pageCount = document.querySelector(".slick-dots")?.childElementCount;
    setSliderCount(pageCount);
    return (
      <p>
        <span>{i + 1} </span>/ {sliderCount}
      </p>
    );
  }

  return (
    <div className="skin-contents__inner">
      <div className="skin-contents__image--info">
        <h2 className="title">건선이란?</h2>

        {/** top */}
        <div className="skin-contents__image--top">
          {info07.map((item) => (
            <div
              className="image-contents title-contents"
              key={shortid.generate()}
            >
              <div className="title-contents__icon">
                <i className={`icon ${item.className}`} />
              </div>
              <strong className="info">{item.title}</strong>
              {item.info.split("\n").map((text) => (
                <p className="sub-info" key={shortid.generate()}>
                  {text}
                </p>
              ))}
            </div>
          ))}
        </div>

        {/** process */}
        <div className="skin-contents__image--process">
          <h3>발병 과정</h3>
          <div className="process-contents">
            <div className="process-contents__inner">
              {info07Process.map((item) => (
                <div
                  className="process-contents__text"
                  key={shortid.generate()}
                >
                  <strong className="info">{item.title}</strong>
                  <p className="sub-info">{item.info}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/** cause */}
        <div className="skin-contents__image--cause">
          <h2 className="title">원인</h2>
          <div className="cause-inner">
            {info07Cause.map((item) => (
              <div
                className="image-contents cause-contents"
                key={shortid.generate()}
              >
                <div className="cause-contents__icon">
                  <i className={`icon ${item.className}`} />
                </div>
                <strong className="info">{item.title}</strong>
                {item.info.split("\n").map((text) => (
                  <p className="sub-info" key={shortid.generate()}>
                    {text}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>

        {/** symptom */}
        <div className="skin-contents__image--symptom">
          <h2 className="title">증상</h2>
          <div className="symptom-bg" />
          <div className="image-text__inner">
            {info07Symptom.map((item) => (
              <div className="image-text" key={shortid.generate()}>
                <strong className="info">{item.title}</strong>
                <p className="sub-info">{item.info}</p>
              </div>
            ))}
          </div>
        </div>

        {/** cure */}
        <div className="skin-contents__image--cure">
          <h2 className="title">치료</h2>
          <div className="cure-bg" />
          <div className="image-text__inner">
            {info07Cure.map((item) => (
              <div className="image-text" key={shortid.generate()}>
                <strong className="info">{item.title}</strong>
                <p className="sub-info">{item.info}</p>
              </div>
            ))}
          </div>
        </div>

        {/** rule */}
        <div className="skin-contents__image--rule">
          <h2 className="title">생활수칙</h2>
          <div className="rule-bg" />
          <div className="image-text__inner">
            {info07Rule.map((item) => (
              <div className="rule-text" key={shortid.generate()}>
                <strong className="info">{item.title}</strong>
                {item.info.split("\n").map((text) => (
                  <p className="sub-info" key={shortid.generate()}>
                    {text}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>

        {/** resource skin info */}
        <div className="skin-contents__image--resource">
          <h2 className="title">건선 질환 정보지</h2>
          <div className="image-text__inner">
            <InfoSlider
              showCount={2}
              scrollCount={2}
              paging={SliderPaging}
              mobileMode
              mobileShowCount={1}
              mobileScrollCount={1}
              mobilePaging={MobileSliderPaging}
              tabList={info07Tab}
              slider1={info07DiseaseResource}
              slider2={info07GuideResource}
              source={info07Source}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info07;
