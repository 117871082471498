import ContactPage from "../pages/contact/ContactPage";

const contactConfigs = [
  /**
   * contact
   */
  {
    path: "/contact",
    component: ContactPage,
    title: "Contact",
  },
];

export default contactConfigs;
