import React, { ReactElement } from "react";
import ReactPlayer from "react-player";

const Info02 = (): ReactElement => {
  return (
    <>
      <div className="medical-contents__inner">
        <div className="medical-info__contents">
          <div className="medical-contents__item--text">
            <h2>아토피피부염의 발병기전</h2>
            <p>
              <span className="date">2021.7.21</span>
              <span>#아토피 #피부질환 #최신치료</span>
            </p>
          </div>

          <div className="medical-info__video">
            <ReactPlayer
              url="/assets/videos/video02.mp4"
              width="100%"
              height="100%"
              controls
              playing
              className="medical-info__video--item"
            />
          </div>

          <div className="medical-info__detail">
            <p>
              아토피(atopy)는 ‘이상한’ 또는 ‘부적절한’이라는 뜻의 그리스어로부터
              유래한 단어로 음식물이나 흡입물 질에 대한 알레르기 반응이
              유전적으로 발생한 경우를 말합니다. 아토피 질환에는 아토피피부염뿐
              아니 라 천식, 알레르기비염, 알레르기결막염 등이 포함됩니다. <br />
              <br />
              아토피피부염은 영유아기와 소아기에 가장 흔히 볼 수 있는
              피부질환입니다. 대개 생후 2-3개월부터 나 타나며, 매우 심한
              가려움증과 반복되는 피부염증이 생깁니다. 이로 인해 아토피피부염은
              환자 자신에게 정신적, 육체적으로 고통을 주며, 가족의 삶의 질까지도
              저하시키게 됩니다. <br />
              <br />
              영상을 통해 아토피피부염의 발병기전과 만성화에 대해 알아보세요.
            </p>

            <a href="/skininfo/5" className="move-skininfo">
              아토피 치료 방법 알아보기
            </a>
            <span>출처: 한국애브비</span>
          </div>
        </div>
      </div>
      <div className="medical-contents__more">
        <p>
          <a href="/medicalinfo/info/5" className="prev">
            이전 글
          </a>
          <a href="/medicalinfo/list" className="list">
            <i className="icon icon-list" />
            목록보기
          </a>
          <a href="/medicalinfo/info/3" className="next">
            다음 글
          </a>
        </p>
      </div>
    </>
  );
};

export default Info02;
