import React, { ReactElement } from "react";
import ReactPlayer from "react-player";

const Info02 = (): ReactElement => {
  return (
    <>
      <div className="medical-contents__inner">
        <div className="medical-info__contents">
          <div className="medical-contents__item--text">
            <h2>건선 치료와 세쿠키누맙 작용기전</h2>
            <p>
              <span className="date">2021.9.3</span>
              <span>#건선 #피부질환 #Secukinumab #최신치료</span>
            </p>
          </div>

          <div className="medical-info__video">
            <ReactPlayer
              url="/assets/videos/video03.mp4"
              width="100%"
              height="100%"
              controls
              playing
              className="medical-info__video--item"
            />
          </div>

          <div className="medical-info__detail">
            <p>
              판상 건선은 피부의 염증을 유발하여 신체 어느 부위나 나타날 수 있는
              두껍고 붉은 비늘 모양의 패치 ‘건선 병변’ 입니다. 판상 건선의
              치료적 접근은 단순한 국소치료제를 넘어 다양한 염증의 근본 요소들을
              표적으로 하는 전신치료제제 요법까지 시간이 지남에 따라
              발전했습니다. 이제 인터루킨 17A를 직접 표적할 수 있습니다.
              인터루킨 17A는 건선에서 염증을 일으키는 주요 염증성 사이토카인으로
              작용하며, 적응 면역 세포와 선천 면역 세포에서 만들어집니다.
              인터루킨 17A는 각질세포 표면의 수용체에 결합하여 각질세포의 활성화
              및 증식을 촉진시킵니다. 이후 백혈구를 병변 부위로 침투시키기 위한
              케모카인의 분비를 시작시킵니다. <br />
              <br />
              세쿠키누맙은 완전 인간 단일 클론 항체로, 인터루킨 17A에 선택적으로
              결합하여, 인터루킨 17A와 수용체의 상호 작용을 억제합니다. 그 결과,
              인터루킨 17A 수용체를 발현하는 각질세포와 다른 세포들에서 조직
              손상과 염증을 일으키는 사이토카인, 케모카인 및 기타 매개체들의
              생성이 감소됩니다. <br />
              <br />
              영상을 통해 셀쿠키누맙을 통한 치료에 대해 알아보세요.
            </p>

            <a href="/skininfo/7" className="move-skininfo">
              건선 치료 방법 알아보기
            </a>
            <span>출처: Novatis</span>
          </div>
        </div>
      </div>
      <div className="medical-contents__more">
        <p>
          <a href="/medicalinfo/info/2" className="prev">
            이전 글
          </a>
          <a href="/medicalinfo/list" className="list">
            <i className="icon icon-list" />
            목록보기
          </a>
          <a href="/medicalinfo/info/1" className="next">
            다음 글
          </a>
        </p>
      </div>
    </>
  );
};

export default Info02;
