import React from "react";
import { withRouter } from "react-router-dom";
import shortid from "shortid";
import { Layouts } from "@container";
import { medicalInfoList } from "./configs";

const MedicalInfoPage = () => {
  return (
    <Layouts>
      <section className="section-header medical-header">
        <div className="section-dimmed" />
        <div className="section-title">
          <h2>전문 의학 지식과 최신 치료 방법을 알아보세요.</h2>
        </div>
      </section>
      <section className="medical-container">
        <div className="medical-contents__inner">
          <div className="medical-contents__list">
            {medicalInfoList.map((item) => (
              <div className="medical-contents__item" key={shortid.generate()}>
                <a href={item.url}>
                  <div className="medical-contents__item--image">
                    <img src={item.image} alt="" />
                  </div>
                  <div className="medical-contents__item--text">
                    <h2>{item.title}</h2>
                    <p>
                      <span className="date">{item.date}</span>
                      <span className="tag">{item.tag}</span>
                    </p>
                    <h3>{item.info}</h3>
                  </div>
                  <div className="medical-contents__item--bottom">
                    <button>
                      <i className="icon icon-more" />
                      자세히 보기
                    </button>
                    <span>{item.source}</span>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    </Layouts>
  );
};

export default withRouter(MedicalInfoPage);
