import React, { Component, ReactNode, Suspense } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import shortId from "shortid";
import routeConfigs from "./routeConfigs";

interface PageRouterProps {
  match: any;
  location: any;
  history: any;
  authenticated: boolean;
}

export const REDIRECT_PATH: string = "/";
class PageRouter extends Component<PageRouterProps | any> {
  render(): ReactNode {
    return (
      <BrowserRouter>
        <Switch>
          <Suspense fallback={<div>Loading...</div>}>
            {routeConfigs.map(
              ({ component: Component, path, exact, title }) => (
                <Route
                  key={shortId.generate()}
                  path={path}
                  exact={exact}
                  render={(routeProps: any): any => {
                    document.title = title ? `AI2U | ${title}` : "AI2U";

                    return <Component {...routeProps} />;
                  }}
                />
              )
            )}
          </Suspense>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default PageRouter;
