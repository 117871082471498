import React, { ReactElement } from "react";
import shortid from "shortid";
import { info12, info12Cure, info12Symptom } from "../configs";

const Info11 = (): ReactElement => {
  return (
    <div className="skin-contents__inner">
      <div className="skin-contents__image--info">
        <h2 className="title">탈모란?</h2>

        {/** top */}
        <div className="skin-contents__image--top">
          {info12.map((item) => (
            <div
              className="image-contents title-contents"
              key={shortid.generate()}
            >
              <div className="title-contents__icon">
                <i className={`icon ${item.className}`} />
              </div>
              <strong className="info">{item.title}</strong>
              {item.info.split("\n").map((text) => (
                <p className="sub-info" key={shortid.generate()}>
                  {text}
                </p>
              ))}
            </div>
          ))}
        </div>

        {/** symptom */}
        <div className="skin-contents__image--symptom">
          <h2 className="title">증상</h2>
          <div className="image-text__inner">
            {info12Symptom.map((item) => (
              <div className="image-text" key={shortid.generate()}>
                <strong className="info">{item.title}</strong>
                {item.info.split("\n").map((text) => (
                  <p className="sub-info" key={shortid.generate()}>
                    {text} <br />
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>

        {/** cure */}
        <div className="skin-contents__image--cure">
          <h2 className="title">진단과 치료</h2>
          <div className="cure-bg" />
          <div className="image-text__inner">
            {info12Cure.map((item) => (
              <div className="image-text" key={shortid.generate()}>
                <strong className="info">{item.title}</strong>
                {item.info.split("\n").map((text) => (
                  <p className="sub-info" key={shortid.generate()}>
                    {text} <br />
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info11;
