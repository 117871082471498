import React from "react";
import shortid from "shortid";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Slider from "react-slick";

export type HeaderProps = {
  showCount: number;
  scrollCount: number;
  paging: any;
  mobileMode?: boolean;
  mobileShowCount?: number;
  mobileScrollCount?: number;
  mobilePaging?: any;
  tabList: any;
  slider1?: any;
  slider2?: any;
  slider3?: any;
  source: string;
  className?: string;
};

const InfoSlider = ({
  showCount,
  scrollCount,
  paging,
  mobileMode,
  mobileShowCount,
  mobileScrollCount,
  mobilePaging,
  tabList,
  slider1,
  slider2,
  slider3,
  source,
  className,
}: HeaderProps) => {
  const slider = {
    dots: true,
    dotsClass: "slick-dots",
    infinite: true,
    speed: 700,
    slidesToShow: showCount,
    slidesToScroll: scrollCount,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    customPaging: paging,

    responsive: mobileMode && [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: mobileShowCount,
          slidesToScroll: mobileScrollCount,
          customPaging: mobilePaging,
        },
      },
    ],
  };

  function PrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="slider-arrow prev" onClick={onClick}>
        <button className="slick-arrow slick-prev" />
      </div>
    );
  }

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <div className="slider-arrow next" onClick={onClick}>
        <button className="slick-arrow slick-next" />
      </div>
    );
  }

  return (
    <div className={className}>
      <Tabs className="resource-tab">
        <TabList>
          {tabList.map((tab) => (
            <Tab key={shortid.generate()}>{tab}</Tab>
          ))}
        </TabList>
        <TabPanel>
          <div className="resource-slider">
            <Slider {...slider}>
              {slider1.map((item) => (
                <div key={shortid.generate()}>
                  <img src={item.img} alt="" />
                </div>
              ))}
            </Slider>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="resource-slider">
            <Slider {...slider}>
              {slider2.map((item) => (
                <div key={shortid.generate()}>
                  <img src={item.img} alt="" />
                </div>
              ))}
            </Slider>
          </div>
        </TabPanel>
        {slider3 && (
          <TabPanel>
            <div className="resource-slider">
              <Slider {...slider}>
                {slider3.map((item) => (
                  <div key={shortid.generate()}>
                    <img src={item.img} alt="" />
                  </div>
                ))}
              </Slider>
            </div>
          </TabPanel>
        )}
      </Tabs>
      <p className="source">{source}</p>
    </div>
  );
};

export default InfoSlider;
