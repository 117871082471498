import CompanyPage from "../pages/company/CompanyPage";

const companyConfigs = [
  /**
   * 회사 소개
   */
  {
    path: "/company",
    component: CompanyPage,
    title: "회사 소개",
  },
];

export default companyConfigs;
