/* eslint-disable react/jsx-no-target-blank */
import React, { ReactNode } from "react";
import shortid from "shortid";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import { Layouts } from "@container";
import { mainBannerList, mainSkinInfoList, mainVideoList } from "./configs";

const MainPage = (): ReactNode => {
  const slider = {
    centerPadding: "0",
    slidesToShow: 3,
  };

  return (
    <Layouts headerColor="white" footerVisible={true}>
      <main>
        {/** 최상단 이미지 */}
        <section className="main-image__area">
          <div className="main-image__inner">
            <div className="main-image__text">
              <h2 className="main-image__title">
                당신을 위한 <br />
                올바른 피부 정보
              </h2>
              <h3 className="main-image__subtitle">
                대학병원 의료진의 다년간의 경험을 토대로 <br />
                가장 효과적인 메디컬 솔루션을 제공합니다.
              </h3>
            </div>
          </div>
        </section>

        {/** company info */}
        <section className="main-contents__wrap">
          <div className="main-contents__inner">
            <div className="main-company__info">
              <div className="main-company__info--inner">
                <div className="main-company__info--item">
                  <i className="icon icon-ai" />
                  <p className="main-company__info--text">
                    AI∙AR∙메타버스
                    <br /> 미래 기술 개발
                  </p>
                </div>

                <div className="main-company__info--item">
                  <i className="icon icon-medical" />
                  <p className="main-company__info--text">
                    AI2U가 만드는
                    <br />
                    새로운 의료 플랫폼
                  </p>
                </div>

                <div className="main-company__info--item">
                  <i className="icon icon-health" />
                  <p className="main-company__info--text">
                    웰니스∙헬스케어
                    <br />
                    토탈 플랫폼
                  </p>
                </div>
              </div>
            </div>

            {/** new medical info */}
            <section className="main-medical__info">
              <div className="main-medical__info--inner">
                <div className="main-medical__info--text">
                  <h2>최신 의학 정보와 올바른 치료 가이드</h2>
                  <h3>전문 의학 정보 및 최신 치료 방법을 제공합니다.</h3>
                </div>

                <div className="main-medical__image">
                  <Slider {...slider}>
                    {mainVideoList.map((item) => (
                      <div
                        className="main-medical__image--inner"
                        key={shortid.generate()}
                      >
                        <ReactPlayer
                          url={item.imgUrl}
                          width={item.width}
                          height={item.height}
                          playing={item.playing}
                          controls={item.controls}
                          playIcon={
                            <button className="btn-play">
                              <i className="icon icon-play" />
                            </button>
                          }
                          light={item.light}
                          className={item.className}
                        />
                        <p>{item.title}</p>
                      </div>
                    ))}
                  </Slider>
                </div>

                <div className="main-info__more">
                  <a href="/medicalinfo/list">
                    <span>영상 자료 더보기</span>
                  </a>
                </div>
              </div>
            </section>
          </div>
        </section>

        {/** banner */}
        <div className="main-banner__area">
          <div className="contents-inner main-banner__inner">
            <div className="main-banner__list">
              {mainBannerList.map((item) => (
                <a href={item.link} target="_blank" key={shortid.generate()}>
                  <div className="main-banner__list--inner">
                    <div className="main-banner__item">
                      <div className="banner">
                        <img src={item.img} alt="" />
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>

        {/** skin info */}
        <div className="main-skin__info">
          <div className="main-skin__info--bg" />
          <div className="contents-inner skin-contents__inner">
            <div className="main-section__title--wrap">
              <div className="main-section__title">피부 의학 정보</div>
            </div>

            <div className="main-skin__info--inner">
              {mainSkinInfoList.map((item) => (
                <div className="main-skin__info--list" key={shortid.generate()}>
                  <a href={item.url}>
                    <div className="main-skin__info--item">
                      {item.title.split("\n").map((text) => (
                        <h3 key={shortid.generate()}>{text}</h3>
                      ))}
                      <span className="main-skin__info--button">
                        더 알아보기
                      </span>
                    </div>
                  </a>
                </div>
              ))}

              <div className="main-info__more skin">
                <a href="/skininfo/1">
                  <span>의학 정보 더 알아보기</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layouts>
  );
};

export default MainPage;
