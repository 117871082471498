import React from "react";
import { RouteProps, Redirect } from "react-router-dom";
import {
  companyConfigs,
  contactConfigs,
  mainConfigs,
  // productConfigs,
  skinInfoConfigs,
  medicalInfoConfigs,
} from "@configs";

export const REDIRECT_PATH: string = "/";

const routeConfigs: RouteProps[] | any = [
  ...mainConfigs,
  // ...productConfigs,
  ...companyConfigs,
  ...contactConfigs,
  ...skinInfoConfigs,
  ...medicalInfoConfigs,

  {
    exact: true,
    path: "/",
    component: () => <Redirect to={REDIRECT_PATH} />,
  },
];

export default routeConfigs;
