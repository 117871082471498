/* eslint-disable camelcase */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

/**
 * API 호출관련 서비스 로직
 */
class RestService {
  private isOnRefreshing: boolean;

  private requestQueue: any[];

  private api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      validateStatus: (status: number): boolean => status < 400,
    });

    this.api.interceptors.response.use(
      this.responseSuccessHandler,
      this.responseErrorHandler
    );
    this.isOnRefreshing = false;
    this.requestQueue = [];
  }

  /**
   * 성공한 요청에 대한 응답 결과 핸들링 함수
   * @param {AxiosResponse | Promise<AxiosResponse>} response 성공 응답 객체
   */
  private responseSuccessHandler = (
    response: AxiosResponse
  ): AxiosResponse | Promise<AxiosResponse> => {
    const { data } = response;
    return data || response;
  };

  /**
   * 실패한 요청에 대한 응답 결과 핸들링 함수
   * @param {AxiosError} error 에러 응답 객체
   */
  private responseErrorHandler = async (error: AxiosError): Promise<any> => {
    const { response } = error;

    if (!response) {
      return Promise.reject(error.toJSON());
    }

    if (response.data) {
      return Promise.reject(response.data);
    }
    return Promise.reject(error.toJSON());
  };

  private retryRequest = (
    config: AxiosRequestConfig,
    token: string
  ): Promise<any> => {
    const originRequest = config;

    if (originRequest.headers.Authorization) {
      originRequest.headers.Authorization = `Bearer ${token}`;
    }
    return axios(originRequest);
  };

  private pushRequestToReqestQueue = (request: any): Promise<any> => {
    this.requestQueue.push(request);
    return this.requestQueue[this.requestQueue.length - 1];
  };

  private onRefreshed = (token: string): void => {
    [...this.requestQueue].map((request) => request(token));
    this.requestQueue = [];
  };

  /**
   * 요청 정보 반환 함수
   * @param {boolean} isSecureReqest 보안요청 여부
   * @param {AxiosRequestConfig} customConfig 요청정보에 추가적으로 담을 옵션
   */
  public getRequestConfig = async (
    isSecureReqest: boolean,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> => {
    let config: AxiosRequestConfig = {};

    if (customConfig) {
      config = Object.assign(config, customConfig);
    }

    return config;
  };

  /**
   * GET 요청
   * @param {string} url 요청을 보낼 주소
   * @param {any} payload 요청시 담을 데이터(HTTP Body)
   * @param {boolean} isSecureReqest 보안요청 여부
   */
  public get = async (
    url: string,
    payload: any = null,
    isSecureReqest: boolean = true
  ): Promise<any> => {
    const config: AxiosRequestConfig = await this.getRequestConfig(
      isSecureReqest,
      {
        data: payload,
      }
    );
    return this.api.get(url, config);
  };

  /**
   * POST 요청
   * @param {string} url 요청을 보낼 주소
   * @param {any} payload 요청시 담을 데이터(HTTP Body)
   * @param {boolean} isSecureReqest 보안요청 여부
   */
  public post = async (
    url: string,
    payload: any = null,
    isSecureReqest: boolean = true
  ): Promise<any> => {
    const config: AxiosRequestConfig = await this.getRequestConfig(
      isSecureReqest
    );
    return this.api.post(url, payload, config);
  };

  /**
   * PUT 요청
   * @param {string} url 요청을 보낼 주소
   * @param {any} payload 요청시 담을 데이터(HTTP Body)
   * @param {boolean} isSecureReqest 보안요청 여부
   */
  public put = async (
    url: string,
    payload: any = null,
    isSecureReqest: boolean = true
  ): Promise<any> => {
    const config: AxiosRequestConfig = await this.getRequestConfig(
      isSecureReqest
    );
    return this.api.put(url, payload, config);
  };

  /**
   * DELETE 요청
   * @param {string} url 요청을 보낼 주소
   * @param {boolean} isSecureReqest 보안요청 여부
   */
  public delete = async (
    url: string,
    isSecureReqest: boolean = true
  ): Promise<any> => {
    const config: AxiosRequestConfig = await this.getRequestConfig(
      isSecureReqest
    );
    return this.api.delete(url, config);
  };

  /**
   * 파일 핸들링 관련 요청이나, ApiService의 기본적인
   * get/post/put/delete 함수가 지원하지 않는 형태의 요청시 사용
   * @param {AxiosRequestConfig} config 요청정보
   */
  public request = (config: AxiosRequestConfig): Promise<any> => {
    return this.api.request(config);
  };
}

export default new RestService();
