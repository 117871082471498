export const mainSliderImageConfig = [
  "banner1",
  "banner2",
  "banner3",
  "banner4",
  "banner5",
  "banner6",
  "banner7",
];

export const mainServiceImageConfig = [
  {
    image: "/assets/images/main-info01.png",
    info: "전문 의학 정보와\n 올바른 치료 가이드",
    subInfo:
      "대학병원 의료진의 다년간의 지식과 경험을\n 바탕으로 안전한 치료 방법을 제시합니다.",
  },
  {
    image: "/assets/images/main-info02.png",
    info: "피부질환 분석 알고리즘 외\n AI∙AR 기술 개발",
    subInfo:
      "미래 기술 개발을 통해 원격 의료 및\n 새로운 형태의 의료 서비스를 제공합니다.",
  },
  {
    image: "/assets/images/main-info03.png",
    info: "웰니스∙헬스케어\n 토탈 플랫폼",
    subInfo:
      "고객의 안전과 편의를 최우선으로 하며\n 건강한 라이프스타일을 제시합니다.",
  },
];

export const mainSkinInfoList = [
  {
    id: 1,
    title: "접촉성 피부염",
    info: "외부 물질의 접촉에 의해 발생하는 피부염으로\n 염색약, 화장품, 섬유 등에 의해 발생합니다. ",
    url: "/skininfo/1",
  },
  {
    id: 2,
    title: "손발톱백선",
    info: "피부사상균에 의한 모든 표재성 감염으로\n 흔한 피부 곰팡이 감염인 무좀이 해당됩니다.",
    url: "/skininfo/2",
  },
  {
    id: 3,
    title: "백반증",
    info: "멜라닌세포의 소실에 의해 탈색된 반점들이\n 전신피부에 발생하는 후천적 질환입니다.",
    url: "/skininfo/3",
  },
  {
    id: 4,
    title: "바이러스사마귀",
    info: "피부 또는 점막에 바이러스 감염이 발생하여\n 표면이 오돌도돌한 구진으로 나타납니다.",
    url: "/skininfo/4",
  },
  {
    id: 5,
    title: "아토피 피부염",
    info: "주로 유아기 또는 소아기에 시작되는 만성적\n 피부질환으로 가려움증, 습진을 동반합니다.",
    url: "/skininfo/5",
  },
  {
    id: 6,
    title: "지루피부염",
    info: "머리, 이마 등 피지 분비가 많은 부위에\n 발생하는 염증성 피부 질환입니다.",
    url: "/skininfo/6",
  },
  {
    id: 7,
    title: "건선",
    info: "비전염성 만성 피부질환으로 초기에는 무릎,\n 팔에서 나타나 확산되는 양상을 보입니다.",
    url: "/skininfo/7",
  },
  {
    id: 8,
    title: "여드름",
    info: "피지가 피부 밖으로 배출되지 못하고\n 모낭 주위에 갇혀 염증을 일으켜 발생합니다.",
    url: "/skininfo/8",
  },
  {
    id: 9,
    title: "대상포진",
    info: "잠복해 있던 수두바이러스가 스트레스나\n 면역 저하로 인해 활성화되어 발생합니다.",
    url: "/skininfo/9",
  },
  {
    id: 10,
    title: "두드러기",
    info: "피부 윗부분이 부풀어 오르면서 가려움증이\n 동반되며, 급성/만성 두드러기로 구분됩니다.",
    url: "/skininfo/10",
  },
  {
    id: 11,
    title: "기미",
    info: "기미란 노출 부위에 발생하는 불규칙한 모양과 다양한 크기의 갈색 점을 말합니다.",
    url: "/skininfo/11",
  },
  {
    id: 12,
    title: "탈모",
    info: "유전을 포함한 여러 요인으로 인해 두피에서 머리숱이 줄어드는 현상을 말합니다.",
    url: "/skininfo/12",
  },
];

export const mainVideoList = [
  {
    imgUrl: "/assets/videos/video04.mp4",
    width: "100%",
    height: "100%",
    playing: true,
    controls: true,
    light: "/assets/images/medical-info04.png",
    className: "medical-info__video--item",
    title: "아토피피부염 질환기전",
  },
  {
    imgUrl: "/assets/videos/video05.mp4",
    width: "100%",
    height: "100%",
    playing: true,
    controls: true,
    light: "/assets/images/medical-info05.png",
    className: "medical-info__video--item",
    title: "아토피피부염 질환기전",
  },
  {
    imgUrl: "/assets/videos/video02.mp4",
    width: "100%",
    height: "100%",
    playing: true,
    controls: true,
    light: "/assets/images/medical-info01.png",
    className: "medical-info__video--item",
    title: "아토피 피부염의 발병기전",
  },
  {
    imgUrl: "/assets/videos/video03.mp4",
    width: "100%",
    height: "100%",
    playing: true,
    controls: true,
    light: "/assets/images/medical-info03.png",
    className: "medical-info__video--item",
    title: "건선 치료와 세쿠키누맙의 작용기전",
  },
  {
    imgUrl: "/assets/videos/video01.mp4",
    width: "100%",
    height: "100%",
    playing: true,
    controls: true,
    light: "/assets/images/medical-info02.png",
    className: "medical-info__video--item",
    title: "건선 치료와 구셀쿠맙의 작용기전",
  },
];

export const mainBannerList = [
  {
    img: "/assets/images/main-banner01.gif",
    link: "http://neopharm.co.kr/",
  },
  {
    img: "/assets/images/main-banner02.png",
    link: "http://ihpnc.co.kr/",
  },
  {
    img: "/assets/images/main-banner03.png",
    link: "http://www.dalimpharm.co.kr/",
  },
  {
    img: "/assets/images/main-banner04.gif",
    link: "http://www.dongkoo.com/kor",
  },
  {
    img: "/assets/images/main-banner05.png",
    link: "http://naver.me/5girMpm2",
  },
  {
    img: "/assets/images/main-banner06.gif",
    link: "http://www.inno-n.com/brand/klederma.asp",
  },
  {
    img: "/assets/images/main-banner07.png",
    link: "https://www.ckdpharm.com/",
  },
  {
    img: "/assets/images/main-banner08.png",
    link: "http://www.dwpharm-dc.com/",
  },
];
