import React, { ReactElement } from "react";
import ReactPlayer from "react-player";

const Info01 = (): ReactElement => {
  return (
    <>
      <div className="medical-contents__inner">
        <div className="medical-info__contents">
          <div className="medical-contents__item--text">
            <h2>건선 치료와 구셀쿠맙의 작용기전</h2>
            <p>
              <span className="date">2021.7.21</span>
              <span>#건선 #피부질환 #구셀쿠맙 #최신치료</span>
            </p>
          </div>

          <div className="medical-info__video">
            <ReactPlayer
              url="/assets/videos/video01.mp4"
              width="100%"
              height="100%"
              controls
              playing
              className="medical-info__video--item"
            />
          </div>

          <div className="medical-info__detail">
            <p>
              건선은 면역체계의 이상으로 발생하는 전신성 염증질환입니다. 우리
              몸의 피부는 일정한 주기로 새로운 세포가 생기는데, 일생을 마친
              세포는 비듬과 같은 피부 껍질로 몸에서 떨어져 나갑니다. 이러한 피부
              재생 과정에 영향을 미치는 T세포가 과도하게 활성화되면, 피부
              바깥층의 각질형성세포가 빠르게 증식하고, 비정상적인 각질이 쌓여
              건선 병변이 생성됩니다. <br />
              <br />
              건선은 유전적인 영향이 크며, 스트레스, 피부의 상처와 자극, 여러
              환경적 요인이 복합적으로 작용하여 발생합니다. 피부가 붉어지는
              홍반, 하얀 각질이 일어나는 인설, 두꺼워진 피부가 특징적인
              증상으로, 주 로 팔꿈치, 무릎, 엉덩이, 두피 등에 발생합니다. 건선은
              만성 질환이기에, 올바른 방법으로 꾸준히 관리하면 재발을 줄이고
              증상을 완화시킬 수 있습니다. <br />
              <br />
              영상을 통해 건선의 구체적인 발생 과정과 구셀쿠맙을 통한 치료에
              대해 알아보세요.
            </p>

            <a href="/skininfo/7" className="move-skininfo">
              건선 치료 방법 알아보기
            </a>
            <span>출처: 노바티스</span>
          </div>
        </div>
      </div>
      <div className="medical-contents__more">
        <p>
          <a href="/medicalinfo/info/3" className="prev">
            이전 글
          </a>
          <a href="/medicalinfo/list" className="list">
            <i className="icon icon-list" />
            목록보기
          </a>
          <span>다음글이 없습니다</span>
        </p>
      </div>
    </>
  );
};

export default Info01;
