export const medicalInfoList = [
  {
    id: "1",
    url: "/medicalinfo/info/4",
    image: "/assets/images/medicalinfo/medical-info04.png",
    video: "/assets/videos/video04.mp4",
    title: "아토피피부염 질환기전",
    date: "2021.9.10",
    tag: "#아토피피부염 #만성면역질환 #가려움증 #원인",
    info: "아토피피부염 질환기전에 대해 알아보세요.",
    source: "출처: Sanofi",
  },
  {
    id: "2",
    url: "/medicalinfo/info/5",
    image: "/assets/images/medicalinfo/medical-info05.png",
    video: "/assets/videos/video05.mp4",
    title: "아토피피부염 질환기전",
    date: "2021.12.14",
    tag: "#아토피피부염 #만성면역질환 #가려움증 #원인",
    info: "아토피피부염 질환기전에 대해 알아보세요.",
    source: "출처: Sanofi",
  },
  {
    id: "3",
    url: "/medicalinfo/info/2",
    image: "/assets/images/medicalinfo/medical-info01.png",
    video: "/assets/videos/video02.mp4",
    title: "아토피피부염의 발병기전",
    date: "2021.7.21",
    tag: "#아토피 #피부질환 #최신치료",
    info: "심한 가려움으로 수면 장애와 피로, 삶의 질을 저하시키는 아토피피부염의 원인을 알아보세요.",
    source: "출처: 한국애브비",
  },
  {
    id: "4",
    url: "/medicalinfo/info/3",
    image: "/assets/images/medicalinfo/medical-info03.png",
    video: "/assets/videos/video03.mp4",
    title: "건선 치료와 세쿠키누맙 작용기전",
    date: "2021.9.3",
    tag: "#건선 #피부질환 #Secukinumab #최신치료",
    info: "판상 건선, 세쿠키누맙을 통한 최신 치료법을 알아보세요.",
    source: "출처: Novatis",
  },
  {
    id: "4",
    url: "/medicalinfo/info/1",
    image: "/assets/images/medicalinfo/medical-info02.png",
    video: "/assets/videos/video01.mp4",
    title: "건선 치료와 구셀쿠맙의 작용기전",
    date: "2021.7.21",
    tag: "#건선 #피부질환 #구셀쿠맙 #최신치료",
    info: "면역체계의 이상으로 발생하는 전신성 염증질환, 건선의 치료에 대해 알아보세요.",
    source: "출처: 한국 얀센",
  },
  // {
  //   id: "3",
  //   url: "/medicalinfo/info/3",
  //   image: "/assets/images/medical-info03.png",
  //   title: "만성 특발성 두드러기 치료를 위한 항 IgE 안내서",
  //   date: "2021.7.21",
  //   tag: "#두드러기 #피부질환 #항 IgE #최신치료",
  //   info: "만성 두드러기의 진단과 항 IgE를 통한 치료 방법을 알아보세요.",
  //   source: "출처: 노바티스",
  // },
];
