// info01
export const info01 = [
  {
    className: "top-img05",
    title: "검진",
    info: "모든 물질에 대한 다각적 \n 인 진단 필요",
  },
  {
    className: "top-img02",
    title: "접촉성",
    info: "외부 물질의 접촉에 의해 \n 발생",
  },
  {
    className: "top-img03",
    title: "증상",
    info: "습진, 괴사병변, 여드름성 \n 병변 등",
  },
  {
    className: "top-img04",
    title: "주요인",
    info: "피부 상태, 부위, 나이, \n 환경요인 (온도, 습도)",
  },
];

export const info01Cause = [
  {
    className: "cause-img05",
    title: "외부 물질의 접촉",
    info: "화장품, 염색약, 연고 등의 \n 약품, 목걸이 등",
  },
  {
    className: "cause-img03",
    title: "환경적 요인",
    info: "온도, 습도",
  },
];

export const info01Symptom = [
  {
    title: "자극성 접촉 피부염",
    info: "자극성 접촉 피부염은 누구에게나 자극적인 물질에 접촉한 경우에 발생하는 피부염으로 직업성 피 부 질환의 가장 흔한 형태입니다. 주로 손이나 발 같은 노출 부위에 생기며 병변이 매우 다양하여 홍반에서 수포나 궤양까지도 생길 수 있습니다. 피부염을 일으키는 데는 물질의 종류 외에도 피부 의 상태, 부위, 나이, 환경요인(온도, 습도) 등이 관여합니다.",
  },
  {
    title: "알레르기성 접촉 피부염",
    info: "알레르기성 접촉 피부염은 어떤 물질에 대해 민감하게 반응하여 발생된 피부염으로, 대부분의 경 우 급성 발진의 형태인 홍반, 구진, 수포 등이 나타납니다. 몸의 어느 부위에나 나타나며 만성인 경우에는 피부가 두꺼워지는 태선화, 각질이 많아지는 인설 등을 보입니다.",
  },
];

export const info01Cure = [
  {
    title: "진단",
    info: "병력이 가장 중요합니다. 환자의 피부염 부위, 나이, 성별, 직업, 취미 및 접촉 가능성이 있는 모든 물질에 대한 다각적인 문진이 필요합니다.",
  },
  {
    title: "치료",
    info: "원칙은 원인 물질 회피입니다. 현재 발생한 접촉성 피부염에 대해서는 약물, 국소 스테로이드, 면역조절 제, 광선치료 등이 사용됩니다.",
  },
];

// info02
export const info02 = [
  {
    className: "top-img04",
    title: "표재성 감염",
    info: "피부곰팡이균에 의한 \n 모든 표재성 감염",
  },
  {
    className: "top-img06",
    title: "환경",
    info: "손발이 습하지 않게 \n 주의 필요",
  },
  {
    className: "top-img03",
    title: "증상",
    info: "가려움 동반, 피부가 희게 \n 짓무르면서 갈라짐",
  },
  {
    className: "top-img05",
    title: "발생 현황",
    info: "무좀이 전체 백선의 약 \n 35%, 20~40대 많이 발생",
  },
];

export const info02Cause = [
  {
    className: "cause-img05",
    title: "외부 물질의 접촉",
    info: "화장품, 염색약, 연고 등의 \n 약품, 목걸이 등",
  },
  {
    className: "cause-img03",
    title: "환경적 요인",
    info: "온도, 습도",
  },
];

export const info02Symptom = [
  {
    info: "여러 임상 증상이 나타날 수 있습니다. 가려움증을 동반하는 경우가 많으며 발가락 사이 피부가 희게 짓무르면서 갈라지기도 하며, 갈라진 틈새로 이차 세균 감염이 발생하기도 합니다.",
  },
  {
    info: "심할 경우 작은 물집이 발생하는 경우도 있고 딱지를 형성하기 도 합니다. 손발톱무좀이 같이 나타나기도 합 니다. 손발톱 무좀의 경우 손발톱이 황색/황갈색으로 변하고 광택이 없어지면서 거칠어집니다.",
  },
];

export const info02Cure = [
  {
    title: "항진균제 연고",
    info: "곰팡이 균에 대해서는 항진균제 연고를 무좀이 생긴 부위와 그 주변부에 발라 줍니다. 심하게 두꺼워진 경우에는 항진균제를 6-12주간 복용해야 합니다. 손발톱 무좀의 경우는 바르는 약이 충분히 침투하지 못하기 때문에 먹는 약을 3-12개월 간 반드시 복용해야 합니다. 발톱이 자라나는 기간이 있기 때문에 먹는 약 복용 후에도 6개월 정도 기다리면서 새로운 발톱이 자랄 때까지 기다립니다.",
  },
  {
    title: "항생제, 염증 치료",
    info: "급성 염증이나 이차 세균 감염이 동반된 경우에는 항생제와 염증 치료를 한 후에 곰팡이균에 대한 치료를 해야 합니다.",
  },
];

export const info02Rule = [
  {
    info: "• 손발이 습한 경우에 자주 발생하기 때문에 이러한 환경을 피하는 것이 가장 중요합니다. \n • 신발이 젖지 않도록 햇볕에 잘 말려줘야 하며, 신발 여러 켤레를 번갈아 신는 것이 좋습니다. \n • 대중목욕탕, 운동시설을 이용하는 경우에 발을 깨끗이 씻어야 합니다. \n • 가족 구성원이 무좀이 있는 경우 수건 등을 분리해서 사용해야 합니다.",
  },
];

// info03
export const info03 = [
  {
    className: "top-img02",
    title: "후천성",
    info: "피부 멜라닌세포의 소실에 \n 의해 발생하는 후천적 질환",
  },
  {
    className: "top-img03",
    title: "증상",
    info: "탈색 반점, 과다색소침착, \n 가려움증",
  },
];

export const info03Cause = [
  {
    className: "cause-img02",
    title: "면역적 요인",
    info: "면역적 요인, 신경학적 요인, \n 멜라닌세포 자가파괴 요인",
  },
  {
    className: "cause-img03",
    title: "환경적 요인",
    info: "스트레스, 외상 등",
  },
];

export const info03Symptom = [
  {
    info: "다양한 크기와 형태의 탈색 반점이 주증상입니다. 때에 따라서 경계가 명확하면서 경계를 따라 과 다색소침착을 나타내기도 하며 때로 홍반성 경계를 띠면서 가려움증이 나타나기도 합니다.",
  },
  {
    info: "이외는 증상이 없는 경우가 흔해 단지 미용상의 문제를 보일 때가 많습니다. 하지만 때에 따라서 눈의 색 소이상, 갑상선 질환, 기타 자가면역질환을 동반하는 경우가 있습니다.",
  },
];

export const info03Cure = [
  {
    title: "진단",
    info: "대개는 따로 검사 필요 X. 2차적으로 생겼을 경우 혈액 검사 등 시행합니다.",
  },
  {
    title: "치료",
    info: "대개 국소 스테로이드 연고, 국소 면역조절제 연고, 광선치료, 엑시머 레이저 치료가 사용됩니다.",
  },
];

// info04
export const info04 = [
  {
    className: "top-img04",
    title: "바이러스",
    info: "피부 또는 점막 유두종 바이 \n 러스의 감염으로 발생",
  },
  {
    className: "top-img06",
    title: "환경",
    info: "따뜻하고 습한 환경에서 \n 더욱 잘 감염됨",
  },
  {
    className: "top-img03",
    title: "증상",
    info: "표면이 오돌도돌한 구진",
  },
];

export const info04Cause = [
  {
    className: "cause-img01",
    title: "직접적인 접촉",
    info: "감염된 사람과의 직접 접촉",
  },
  {
    className: "cause-img02",
    title: "후천성 면역결핍",
    info: "",
  },
  {
    className: "cause-img03",
    title: "환경적 요인",
    info: "따뜻하고 습한 환경",
  },
  {
    className: "cause-img04",
    title: "바이러스 입자",
    info: "감염, 약물",
  },
];

export const info04Symptom = [
  {
    info: "손, 발, 다리, 얼굴 등에 주로 발생합니다. 성 접촉을 통해 성기에도 발생합니다. \n 대부분의 사마귀는 통증이 없지만 일부 사마귀는 손을 대면 통증이 느껴지기도 합니다. ",
  },
  {
    info: "발에 난 사마귀는 서 있거나 걸을 때 통증을 유발할 수 있습니다. 사마귀가 있는 부위를 면도할 경우 검은 색 점이 나타날 수 있습니다.",
  },
];

export const info04Cure = [
  {
    title: "바르는 약",
    info: "두꺼워진 피부층을 없애는 살리실산이 포함된 연고를 사용합니다.",
  },
  {
    title: "냉동치료",
    info: "얼려서 바이러스를 제거하는 냉동치료를 주로 시행합니다.",
  },
];

export const info04Rule = [
  {
    info: "• 사마귀 치료 도구는 별도로 관리합니다. \n • 사마귀 병변과 직접적인 접촉을 피해야 합니다. \n • 다른 부위에 옮겨가지 않도록 병변을 자극하지 않는 것이 좋습니다.",
  },
];

// info05
export const info05 = [
  {
    className: "top-img02",
    title: "염증성",
    info: "만성적이고 재발성의 \n 염증성 피부질환",
  },
  {
    className: "top-img07",
    title: "발생 현황",
    info: "주로 유아기 혹은 \n소아기에 시작",
  },
  {
    className: "top-img03",
    title: "증상",
    info: "진물, 가피(딱지), 피부가 \n 두껍게 보이는 현상(태선화)",
  },
];

export const info05Cause = [
  {
    className: "cause-img02",
    title: "유전성 질환",
    info: "",
  },
  {
    className: "cause-img03",
    title: "환경적 요인",
    info: "개인위생, 도시화, \n 추운 기후",
  },
  {
    className: "cause-img04",
    title: "감염",
    info: "전신 및 피부 감염, \n 예방주사 접종",
  },
];

export const info05Symptom = [
  {
    info: "유아기(2개월~2세): 초기에는 뺨이나 이마, 머리에 잘 생깁니다. 몸통이 거칠고 건조하며, 팔다리는 접히는 부위보다는 팔다리의 바깥쪽에 피부염이 있는 경우가 많습니다. 유아기에는 진물이나 가피 (딱지) 형태의 급성 병변의 양상을 흔히 나타납니다.",
  },
  {
    info: "소아기(2세~10세): 피부 병변이 얼굴은 오히려 덜 침범되는데 반하여 팔오금, 오금, 목 같이 접히는 부위는 후기로 갈수록 침범이 뚜렷해지며, 건조한 형태로 나타나는 경우가 많습니다.",
  },
  {
    info: "사춘기와 성인기: 오랫동안 긁어 피부가 두껍게 보이는 현상(태선화)이 뚜렷하게 나타나며, 목 같은 접히는 부위 뿐 아니라 얼굴이나 손도 흔히 침범됩니다.",
  },
];

export const info05Cure = [
  {
    title: "바르는 약",
    info: "보습제를 사용하여 관리하는 것이 기본이며, 추가로 악화될 때마다 스테로이드 연고나 국소 면역 조절제를 사용합니다. 스테로이드 연고는 아토피 피부염에서 가장 흔히 사용되는 치료제로, 무분별하게 사용하면 피부의 모세혈관이 확장되거나 피부가 위축되는 등 부작용을 초래하기도 하지만, 적절히 사용하면 많은 효과를 볼 수 있으므로 반드시 피부과 전문의에게 치료를 받는 것이 중요합니다.",
  },
  {
    title: "국소 면역 조절제",
    info: "스테로이드 연고를 대신 할 수 있는 약제로, 효과는 중간 강도의 스테로이드 연고와 비슷하지만 장기간 사용해도 부작용이 거의 없습니다. 2세 이상의 소아나 성인의 얼굴이나 목과 같이 피부가 얇고 약한 부위에 나타나는 아토피피부염에 효과적입니다.",
  },
  {
    title: "항히스타민제",
    info: "1세대 항히스타민제는 수면을 유도하는 진정 작용이 있어 가려움증으로 잠을 이루지 못하는 경우 에 사용할 수 있으며, 졸음이 오지 않는 2세대 항히스타민제는 진정 작용이 심한 환자나 운전 기사, 공부하는 학생에게 사용됩니다.",
  },
  {
    title: "감마리놀렌산",
    info: "필수지방산을 많이 함유하고 있는 달맞이꽃 종자유는 호전 상태를 유지하고 재발을 막는 보조 치료제로 사용됩니다.",
  },
];

//info06
export const info06 = [
  {
    className: "top-img04",
    title: "염증성",
    info: "피지의 분비가 많은 부위에 \n 잘 발생하는 만성 염증성 \n 피부 질환",
  },
  {
    className: "top-img05",
    title: "발생 현황",
    info: "생후 3개월 이내, 40~70세 사이에 발생 빈도가 높음, 성인 남자의 3~5%에서 발생",
  },
  {
    className: "top-img03",
    title: "증상",
    info: "홍반, 인설, 딱지 \n 다양한 양상의 각질 형태",
  },
];

export const info06Cause = [
  {
    className: "cause-img02",
    title: "유전적 요인",
    info: "",
  },
  {
    className: "cause-img03",
    title: "환경적 요인",
    info: "온도와 습도가 낮은 환경",
  },
  {
    className: "cause-img04",
    title: "외부 자극",
    info: "세균 감염, 호르몬의 영향",
  },
];

export const info06Symptom = [
  {
    info: "비듬: 두피의 쌀겨모양의 표피탈락이 생기는 것을 말합니다.",
  },
  {
    info: "겨드랑이: 겨드랑이 부위에는 발진이 양측성으로 꼭지에서 시작되어 주변의 피부로 퍼집니다. 데오도란트에 의한 알레르기성 접촉 피부염과 유사한 모양을 나타냅니다.",
  },
  {
    info: "얼굴: 얼굴의 지루성 피부염은 뺨, 코, 이마의 구진성 발진으로 나타날 수 있습니다. 눈썹에서 쉽게 벗겨 지는 비늘과 홍반이 발견되 고, 비늘 밑의 피부는 붉은색을 띕니다. 눈꺼풀은 황적색을 띠며, 미세 한 비늘로 덮여 있습니다.",
  },
  {
    info: "사타구니: 사타구니 부위와 둔부 사이의 주름에도 백선 감염, 칸디다증, 건선과 유사한 양상으로 지루성 피부 염이 발생할 수 있습니다. 하지만 비늘이 미세하고 경계가 덜 명확하며, 양측성과 대칭성의 경향이 있습니다.",
  },
  {
    info: "귀: 귀에서 생긴 지루성 피부염은 감염으로 인한 외이도염으로 오진될 수 있습니다. 외이도에는 심한 가려움증을 동반한 비늘이 발생하며, 귀 뒤쪽, 귓불 아래의 피부에도 발생할 수 있습니다.",
  },
];

export const info06Cure = [
  {
    title: "바르는 약",
    info: "붉은 정도가 심할 경우에는 스테로이드 연고, 원인균이 곰팡이일 경우 항진균제 크림 사용.",
  },
];

export const info06Rule = [
  {
    info: "• 약용 샴푸 사용하여 머리를 감는 것이 좋습니다. \n • 증상이 심할 때 머리 를 하루에 한 번 감고, 증상이 좋아지면 일주일에 2~3번 감는 것이 좋습니다. \n • 얼굴에는 세척력이 강한 비누의 사용을 피하고, 알코올 성분이 적은 저자극성 크림을 사용합니다. \n • 면도 전후에 사용하는 알코올 성분의 면도용 로션은 피하는 것이 좋습니다.",
  },
];

// info07
export const info07 = [
  {
    className: "top-img01",
    title: "비전염성",
    info: "면역계 이상에 의해 \n 발생합니다.",
  },
  {
    className: "top-img02",
    title: "만성",
    info: "호전과 악화가 반복되므로 \n 꾸준한 관리가 필요합니다.",
  },
  {
    className: "top-img03",
    title: "증상",
    info: "하얗고 두꺼운 각질 \n 좁쌀같은 붉은 색 발진",
  },
  {
    className: "top-img04",
    title: "합병증",
    info: "건선성 관절염 \n 심혈관계 질환",
  },
];

export const info07Process = [
  {
    title: "피부 발진",
    info: "면역세포 증가",
  },
  {
    title: "각질 형성",
    info: "각질세포 자극, 각질 증식",
  },
  {
    title: "건선 발생",
    info: "면역세포 증가",
  },
];

export const info07Cause = [
  {
    className: "cause-img01",
    title: "면역계 이상",
    info: "T면역세포 활성화",
  },
  {
    className: "cause-img02",
    title: "유전적 요인",
    info: "가족력",
  },
  {
    className: "cause-img03",
    title: "환경적 요인",
    info: "건조하고 추운 날씨 \n 스트레스, 식습관",
  },
  {
    className: "cause-img04",
    title: "외부 자극",
    info: "감염, 약물",
  },
];

export const info07Symptom = [
  {
    title: "안면 건선",
    info: "눈썹 주위, 인중, 이마 윗부분, 헤어라인 등에 주로 발생하며, 민감한 부위이므로 치료 시 주의가 필요합니다. ",
  },
  {
    title: "두피 건선",
    info: "매우 일반적인 건선 증상 중 하나로, 전체 환자 중 50%~80%가 두피 건선을 호소합니다. 심각할 경우 두껍고 딱딱한 각질이 전체 두피를 덮을 수 있고, 이마, 목 뒤, 귀 근처로까지 확대되기도 합니다. 가려움증 및 비듬과 각질은 가장 흔한 증상입니다. ",
  },
  {
    title: "팔 다리 건선",
    info: "일반적인 특징은 피부 병변과 함께 균열, 수포, 부종 등을 동반한다는 점입니다. 팔꿈치 또는 무릎에 발생하는 경우가 많은데, 피부가 두꺼워지거나 각질의 과형성, 발적이 일어날 수 있습니다.",
  },
  {
    title: "생식기 건선",
    info: "주로 간찰부위 건선이 대표적이며, 초기에는 매끈하고 건조한 붉은 병변이 보입니다.",
  },
  {
    title: "손, 발 (손톱, 발톱) 건선",
    info: "일반적으로 건선 환자의 50% 이상에서 손발톱의 변화가 일어나는데, 손발톱 모양이 얇아지거나 깊게 패이고 두꺼워지거나 박리될 수 있습니다.",
  },
];

export const info07Cure = [
  {
    title: "바르는 약",
    info: "연고, 로션, 겔 등의 치료제를 발라 건선을 치료하는 방법. 비타민 D 제제, 비타민 D 복합 제, 스테로이드 제제, 비타민 A 제제, 타르제제 사용.",
  },
  {
    title: "먹는 약",
    info: "국소 치료제 또는 광치료(UV)에 반응이 없거나 부작용이 생긴 환자에게 권고. 합성 비타민 A 제제인 ‘레티노이드’, ‘사이클로스포린’, ‘메토트렉세이트’ 사용. 간 독성, 신 독성 부작용을 일으킬 수 있어 사용시 주의 필요.",
  },
  {
    title: "광치료",
    info: "건선이 생긴 부위에 자외선을 쪼이는 방법. 광치료법, 광화학치료법, 단일파장 자외선 B 요법 사용.",
  },
  {
    title: "생물학적제제",
    info: "중증의 심한 건선 환자에게 권고되며, 피부 또는 근육에 주사. 염증 반응에 관여하는 IL 또는 TNF-α를 억제해 증상 완화. 치료제에 따라 효과가 나타나는 속도와 범위가 다르므로 환자에게 적합한 치료제를 선택하는 것이 중요.",
  },
];

export const info07Rule = [
  {
    title: "일상생활",
    info: "• 실내 온도와 습도를 적절하게 유지하고, 보습제를 발라주세요. \n • 피부에 자극이 적은 면 소재의 옷을 선택해주세요. \n • 연고, 크림으로 기름진 옷은 세제를 푼 미지근한 물에 세탁하세요. \n• 새 옷은 반드시 세탁 후 착용하세요. \n• 손발톱은 짧게 유지해주세요. \n• 술, 담배를 멀리 해주세요.",
  },
  {
    title: "야외활동",
    info: "• 지나친 직사광선에 노출되지 않도록 오후 12~2시 사이는 피해주세요. \n • 수영장 물에는 염소가 포함되어 있기에 수영 후에는 반드시 샤워해주세요. \n• 땀이 많이 나는 격한 운동은 자제해주세요. \n• 무분별한 일광욕은 증상을 악화할 수 있으니 삼가주세요.",
  },
  {
    title: "목욕",
    info: "• 장시간 목욕이나 사우나는 피하고, 목욕 후 보습제를 수시로 발라주세요. \n • 스크럽제 사용을 삼가고, 약산성의 순한 비누를 사용하세요. \n • 순한 샴푸로 하루에 1회 살살 머리를 감아주세요. \n • 미지근한 바람으로 머리를 말리고 빗살이 굵은 빗으로 살살 빗질하세요. \n • 염색, 파마 등 화학 제품을 이용한 시술을 하기 전 전문의와 상담하세요.",
  },
];

const baseUrl = "/assets/images/skininfo";

export const info07DiseaseResource = [
  {
    img: baseUrl + "/info07/geonseon01.png",
  },
  {
    img: baseUrl + "/info07/geonseon02.png",
  },
  {
    img: baseUrl + "/info07/geonseon03.png",
  },
  {
    img: baseUrl + "/info07/geonseon04.png",
  },
  {
    img: baseUrl + "/info07/geonseon05.png",
  },
  {
    img: baseUrl + "/info07/geonseon06.png",
  },
  {
    img: baseUrl + "/info07/geonseon07.png",
  },
  {
    img: baseUrl + "/info07/geonseon08.png",
  },
  {
    img: baseUrl + "/info07/geonseon09.png",
  },
  {
    img: baseUrl + "/info07/geonseon10.png",
  },
  {
    img: baseUrl + "/info07/geonseon11.png",
  },
  {
    img: baseUrl + "/info07/geonseon12.png",
  },
  {
    img: baseUrl + "/info07/geonseon13.png",
  },
  {
    img: baseUrl + "/info07/geonseon14.png",
  },
  {
    img: baseUrl + "/info07/geonseon15.png",
  },
  {
    img: baseUrl + "/info07/geonseon16.png",
  },
  {
    img: baseUrl + "/info07/geonseon17.png",
  },
  {
    img: baseUrl + "/info07/geonseon18.png",
  },
  {
    img: baseUrl + "/info07/geonseon19.png",
  },
];

export const info07GuideResource = [
  {
    img: baseUrl + "/info07/geonseon20.png",
  },
  {
    img: baseUrl + "/info07/geonseon21.png",
  },
  {
    img: baseUrl + "/info07/geonseon22.png",
  },
  {
    img: baseUrl + "/info07/geonseon23.png",
  },
];

export const info07Tab = ["질환에 대한 정보", "환자를 위한 가이드"];
export const info07Source = "출처: 한국 애브비";

// info08
export const info08 = [
  {
    className: "top-img02",
    title: "만성",
    info: "털을 만드는 모낭에 붙어 \n 있는 피지선에 발생하는 \n 만성 염증성 질환",
  },
  {
    className: "top-img03",
    title: "주 원인",
    info: "피지가 피부 밖으로 배출되지 못하고 모낭 주위에 갇혀 \n박테리아가 번식",
  },
  {
    className: "top-img05",
    title: "발생 현황",
    info: "보통 사춘기에 발생, \n 어른에게도 나타남",
  },
];

export const info08Cause = [
  {
    className: "cause-img06",
    title: "피지분비 증가",
    info: "",
  },
  {
    className: "cause-img02",
    title: "유전적 요인",
    info: "가족력",
  },
  {
    className: "cause-img03",
    title: "환경적 요인",
    info: "기름이 많은 화장품, \n 마찰, 기계 자극, 스트레스",
  },
  {
    className: "cause-img04",
    title: "염증반응",
    info: "",
  },
];

export const info08Symptom = [
  {
    title: "면포, 구진, 농포",
    info: "여드름의 증상으로 면포, 구진, 농포 등이 나타납니다. 심한 경우에는 결절이나 낭종이 생깁니다. 면포란 피지가 축적되어 모낭이 팽창한 것입니다. 면포에는 모낭 입구가 열려 있습니다. 면포에는 개방 면포와 폐쇄 면포라는 두 종류가 있습니다. 흔히 블랙헤드라고도 하는 개방 면포는 그간 축적된 피지의 색이 검게 보이는 것입니다. ",
  },
  {
    title: "",
    info: "폐쇄 면포는 표면이 닫혀 있는 면포로, 흔히 화이트헤드라고 합니다. 면포가 오래되면 주위에 세균이 증식하고, 그 주위에 염증 세포가 모이면 붉은 여드름 구진, 곪는 여드름 농포를 형성합니다. 이 정도가 심해지면 결절과 낭종 등이 부어있는 상태가 됩니다.",
  },
];

export const info08Cure = [
  {
    title: "먹는 약, 바르는 약",
    info: "여드름을 치료하는 원리는 막힌 모낭을 제거하여 피지가 잘 배출되도록 하거나, 세균의 성장과 염증, 피지 분비를 억제하는 것입니다. 여드름의 심한 정도와 형태에 따라서 먹는 약과 바르는 약을 단독으로 혹은 복합하여 선택합니다. 이러한 약에는 부작용이 있을 수 있으므로 의사의 처방이 필요합니다.",
  },
  {
    title: "외과적인 치료",
    info: "증상 부위에 대한 주사 요법, 여드름 압출 치료, 박피술, 레이저 치료, 광역동 치료 등이 있습니다.",
  },
];

export const info08Rule = [
  {
    info: "• 뜨겁지 않은 온수와 부드러운 세정제로 하루에 두 번씩 세안합니다. \n • 함부로 병변을 짜지 않습니다. \n • 매일 병변부에 벤조일 페록사이드를 바릅니다. \n • 직업성 여드름이 있다면 원인물질과 장기간 접촉하지 않도록 의복을 깨끗이합니다.",
  },
];

// info09
export const info09 = [
  {
    className: "top-img02",
    title: "수두 바이러스",
    info: "잠복하던 수두 바이러스가 \n 다시 활성화되며 발생",
  },
  {
    className: "top-img03",
    title: "증상",
    info: "피부에 발진과 특징적인 물집 형태가 나타남, 통증 동반",
  },
  {
    className: "top-img05",
    title: "발생 현황",
    info: "면역력이 떨어지는 60세 \n 이상의 성인에게서 발병",
  },
];

export const info09Cause = [
  {
    className: "cause-img01",
    title: "면역계 이상",
    info: "고령, 암환자",
  },
  {
    className: "cause-img04",
    title: "수두 바이러스",
    info: "과거 수두에 걸렸거나 \n 수두 예방접종을 한 경우",
  },
  {
    className: "cause-img03",
    title: "환경적 요인",
    info: "스트레스, 외상",
  },
];

export const info09Symptom = [
  {
    title: "통증과 이상 감각, 발진",
    info: "발진이 나타날 자리에 4-5일 전부터 통증과 이상 감각이 나타납니다. 피부 발진은 통증이 있었던 자리에 나타나며 몸 정중앙을 중심으로 한쪽에만 띠 모양으로 발생합니다. 발진이 생기고 12-24 시간 내에 수포를 형성하며 7-10일 지나면 딱지가 앉아 총 3주 정도 피부 증상이 나타나게 됩니다. ",
  },
  {
    title: "",
    info: "나이가 많을수록 통증이 심하게 나타나며, 침범하는 신경의 위치에 따라 눈, 귀, 방 광에도 증상이 나타날 수 있어 주의를 요합니다. 수두보다 전염력이 약한 것으로 알려져 있으나 발진 발생 후 7일까지는 바이 러스가 전염될 수 있으므로 주의해야 합니다.",
  },
];

export const info09Cure = [
  {
    title: "진단",
    info: "특징적인 증상으로 진단되는 경우가 대부분입니다. 진단이 어려운 경우 수포를 터뜨려서 수포액에서 바이러스가 감염된 세포를 관찰하는 것이 도움이 될 수 있으며 혈액 검사를 통해 바이러스가 존재하는지 확인해 볼 수도 있습니다.",
  },
  {
    title: "치료",
    info: "발진이 생기고 3일 이내에 경구 항바이러스제를 투여하면 통증 기간이 줄어들고 합병증 발생률이 감소합니다. 경구 항바이러스제를 투약해야 합니다. 이차 세균 감염이 생길 수 있으므로 상처 관리도 중요합니다. 피부가 호전된 후에도 1-3개월 간 통증이 지속되는 경우가 있는데 이를 포진후신경통이라고 합니다. 치료가 어려우나 환자의 70%가 1년 내에 호전을 보인다고 합니다. 면역이 떨어져 있는 환자의 경우에는 전신으로 대상 포진이 번질 수도 있습니다. 대상포진을 치료하다가 갑자기 악화된다면 이차 세균 감염을 의심해볼 수 있습니다.",
  },
];

// info10
export const info10 = [
  {
    className: "top-img02",
    title: "만성/급성",
    info: "원인을 찾을 수 없는 만성 \n 약물, 음식물로 인한 급성",
  },
  {
    className: "top-img03",
    title: "증상",
    info: "피부 윗부분이 부풀고 \n 일시적 가려움증 동반 \n 홍색의 팽진",
  },
  {
    className: "top-img04",
    title: "합병증",
    info: "대부분 피부 증상만 \n 보이지만, 전신 증상도 \n 동반될 수 있음",
  },
];

export const info10Cause = [
  {
    className: "cause-img02",
    title: "만성",
    info: "",
  },
  {
    className: "cause-img04",
    title: "외부 자극",
    info: "약물, 음식물",
  },
];

export const info10Symptom = [
  {
    title: "팽진, 가려움증",
    info: "수 mm에서 10cm 이상에 이르기 까지 다양한 크기의 팽진이 발생할 수 있으며 팽진끼리 합쳐지기도 하고 위치가 변하기도 합니다. 가려움이 동반되며 몸의 어디에도 발생할 수 있습니다. 일반적으로 3-4시간 만에 가라앉았다가 다른 곳에 다시 생깁니다.",
  },
  {
    title: "전신 증상",
    info: "복통, 메스꺼움, 구토 등의 위장관 증상이나 쉰 목소리, 호흡 곤란 등의 증상이 나타날 때에는 반드시 응급실을 방문해야 합니다.",
  },
];

export const info10Cure = [
  {
    title: "진단",
    info: "환자로부터 증상에 대한 병력을 청취하고 의심되는 원인을 찾아내는 것이 중요합니다.알레르기가 의심될 때는 알레르기 검사를 할 수 있습니다. \n 운동/추위/열/물 등의 물리적 자극 요인이 의심될 때는 의심되는 것을 직접 피부에 가한 후 두드러기가 생기는 것을 확인하는 유발 검사를 진행해 볼 수 있습니다. 전신 질환에 의한 두드러기가 의심되는 경우에는 혈액 검사를 시행하기도 합니다.",
  },
  {
    title: "치료",
    info: "가장 중요한 것은 두드러기의 원인으로 의심되는 것을 제거하고 피하는 것입니다. 또한, 증상이 나타나지 않더라도 규칙적으로 약물을 복용하는 것이 중요한데 경구 항히스타민제 흔히 복용합니다. \n 치료 효과가 나타나기까지 1-4주 정도가 걸리므로 꾸준히 복용하는 것이 좋습니다. 조절되지 않는 경우 경구 면역 억제제를 복용하기도 하며, 경우에 따라 주사 면역억제제 치료를 시도하기도 합니다.",
  },
];

export const info10Rule = [
  {
    info: "• 몸에 열이 나게 하거나, 덥게 하는 것들(지나친 난방, 뜨거운 목욕)을 피합니다. \n • 술, 맵고 뜨거운 음식, 자극적인 음식, 스트레스를 피해야 합니다. \n • 두드러기가 난 부위를 긁는 것을 피해야 합니다. \n • 미지근한 물이나 찬 물로 샤워를 하고 냉찜질을 하는 것이 도움이 됩니다.",
  },
];

export const info10InfoResource = [
  {
    img: baseUrl + "/info10/urticaria01.png",
  },
  {
    img: baseUrl + "/info10/urticaria02.png",
  },
  {
    img: baseUrl + "/info10/urticaria03.png",
  },
  {
    img: baseUrl + "/info10/urticaria04.png",
  },
  {
    img: baseUrl + "/info10/urticaria05.png",
  },
  {
    img: baseUrl + "/info10/urticaria06.png",
  },
  {
    img: baseUrl + "/info10/urticaria07.png",
  },
  {
    img: baseUrl + "/info10/urticaria08.png",
  },
  {
    img: baseUrl + "/info10/urticaria09.png",
  },
  {
    img: baseUrl + "/info10/urticaria10.png",
  },
  {
    img: baseUrl + "/info10/urticaria11.png",
  },
  {
    img: baseUrl + "/info10/urticaria12.png",
  },
  {
    img: baseUrl + "/info10/urticaria13.png",
  },
  {
    img: baseUrl + "/info10/urticaria14.png",
  },
  {
    img: baseUrl + "/info10/urticaria15.png",
  },
];

export const info10CureResource = [
  {
    img: baseUrl + "/info10/urticaria16.png",
  },
  {
    img: baseUrl + "/info10/urticaria17.png",
  },
  {
    img: baseUrl + "/info10/urticaria18.png",
  },
  {
    img: baseUrl + "/info10/urticaria19.png",
  },
  {
    img: baseUrl + "/info10/urticaria20.png",
  },
  {
    img: baseUrl + "/info10/urticaria21.png",
  },
  {
    img: baseUrl + "/info10/urticaria22.png",
  },
  {
    img: baseUrl + "/info10/urticaria23.png",
  },
];

export const info10CareResource = [
  {
    img: baseUrl + "/info10/urticaria24.png",
  },
  {
    img: baseUrl + "/info10/urticaria25.png",
  },
  {
    img: baseUrl + "/info10/urticaria26.png",
  },
  {
    img: baseUrl + "/info10/urticaria27.png",
  },
  {
    img: baseUrl + "/info10/urticaria28.png",
  },
  {
    img: baseUrl + "/info10/urticaria29.png",
  },
  {
    img: baseUrl + "/info10/urticaria30.png",
  },
  {
    img: baseUrl + "/info10/urticaria31.png",
  },
];

export const info10Tab = [
  "만성 두드러기의 이해",
  "만성 두드러기의 진단/치료",
  "만성 두드러기의 관리",
];
export const info10Source = "출처: Novatis";

// info11
export const info11 = [
  {
    className: "top-img02",
    title: "멜라닌 색소",
    info: "멜라닌 색소가 과다하게 \n 침착되는 질환",
  },
  {
    className: "top-img03",
    title: "증상",
    info: "얼굴 양 측면에 나타나는 \n 거무스름한 얼룩점",
  },
];

export const info11Cause = [
  {
    className: "cause-img02",
    title: "호르몬",
    info: "임산부에서 흔하게 악화 \n 피임약에 의해 발생",
  },
  {
    className: "cause-img07",
    title: "햇빛",
    info: "자외선을 많이 쬐게 되면 \n 멜라닌이 많이 형성",
  },
];

export const info11Symptom = [
  {
    title: "거무스름한 반점",
    info: "광대뼈, 뺨 등 주로 햇빛에 많이 노출되는 얼굴에 거무스름한 반점이 관찰됩니다. 통증이나 가려움 같은 증상은 없습니다.",
  },
  {
    title: "",
    info: "대부분 얼굴 양 측면에 동일한 형태로 나타나며, 짙은 갈색에서부터 회색까지 다양한 색상으로 나타납니다.",
  },
];

export const info11Cure = [
  {
    title: "햇빛 차단",
    info: "기미의 치료 및 예방에는 햇빛 차단이 가장 중요합니다. 얼굴과 목, 팔, 손등 같은 모든 노출 부위에는 하루도 빠짐없이 햇빛 차단제를 꼼꼼히 바르는 것이 좋습니다. 자외선은 흐린 날이더라도 맑은 날의 60% 강도로 구름을 뚫고 우리 피부에 영향을 미치므로, 하루 종일 비가 내리는 날이 아니라면 흐린 날에도 햇빛 차단에 신경 써야 합니다.",
  },
  {
    title: "국소 치료",
    info: "하이드로퀴논이나 아젤라인산 크림 등 바르는 형태의 약을 수개월간 꾸준히 바르면 효과가 있습니다. 레이저를 이용해 기미가 생긴 피부층을 제거를 시도해볼 수 있습니다.",
  },
];

export const info11Rule = [
  {
    info: "• 태양 광선을 피하고 노출 시 일광차단제를 바릅니다. \n • 자외선 차단지수 30 이상의 자외선 차단제를 바릅니다.",
  },
];

// info12
export const info12 = [
  {
    className: "top-img08",
    title: "유전적 요인",
    info: "가족력, 모낭에 대한 \n 자가면역질환",
  },
  {
    className: "top-img02",
    title: "호르몬의 작용",
    info: "남성호르몬이 \n 모발 성장 억제",
  },
  {
    className: "top-img09",
    title: "스트레스",
    info: "원형 탈모는 \n 스트레스로 악화",
  },
  {
    className: "top-img05",
    title: "발생 현황",
    info: "남성형 탈모는 성인의 약 \n 15~20% 발생",
  },
];

export const info12Symptom = [
  {
    title: "안드로겐 탈모",
    info: "안드로겐탈모는 가장 흔한 탈모질환으로 남자에서는 남성형탈모, 여성에서는 여성형탈모라 합니다. 남성형 탈모는 주로 정수리와 전두부 앞 이마선에서 시작하여 M자 형으로 점점 진행하게 됩니다. 급격히 일어나기 보다는 서서히 발생합니다. 주로 20대 중반에 시작되어 나이가 들면서 서서히 진행하는 양상을 보입니다. 남성형 탈모의 경우, 한국인에서 성인의 약 15~20%에서 발생합니다. \n 여성형 탈모의 경우 앞머리 이마선은 유지되지만 정수리 모발이 적어지고 가늘어지는 특징이 있습니다.",
  },
  {
    title: "원형탈모",
    info: "비교적 흔한 질환으로 두피에 하나 혹은 여러 개의 원형/타원형 탈모반이 발생합니다. 원형탈모가 심하여 두피의 모든 모발이 빠지는 경우 전두 탈모, 전신이 모든 모발이 빠지는 경우 전신탈모라고 부릅니다. \n 탈모의 양상, 감탄 부호 모발, 손발톱의 변화 등으로 진단할 수 있습니다.",
  },
];

export const info12Cure = [
  {
    title: "안드로겐 탈모",
    info: "약물 치료로는 바르는 약제와 경구 약제가 있습니다. 바르는 약제인 미녹시딜은 발모 작용을 하며 일부의 환자의 경우 4-6주 후 탈모가 증가할 수 있는데 이는 긍정적인 현상으로 볼 수 있습니다. \n 여자의 경우에는 남자의 경우보다 좀더 낮은 용량을 이용하는 것이 권장됩니다. 경구 약제로 피나스테라이드가 사용되는데, 이는 남성호르몬이 더 강력한 형태로 변하는 것을 막는 역할을 합니다. \n 여성에게는 경구 약제가 금기입니다. 진행된 남성형 탈모 환자의 경우 모발 이식술을 시행할 수 있습니다.",
  },
  {
    title: "원형 탈모",
    info: "바르는 약제인 미녹시딜은 발모 작용을 하며 일부의 환자의 경우 4-6주 후 탈모가 증가할 수 있는데 이는 긍정적인 현상으로 이해할 수 있습니다. \n 여자의 경우에는 남자의 경우보다 좀더 낮은 용량을 이용하는 것이 권장됩니다. 경구 약제로 피나스테라이드가 사용되는데, 이는 남성호르몬이 더 강력한 형태로 변하는 것을 막는 역할을 합니다. \n 여성에게는 경구 약제가 금기입니다. 진행된 남성형 탈모 환자의 경우 모발 이식술을 시행해볼 수 있습니다.",
  },
];
