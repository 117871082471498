import MainPage from "../pages/main/MainPage";

const mainConfigs = [
  /**
   * 메인
   */
  {
    path: "/",
    component: MainPage,
    exact: true,
  },
];

export default mainConfigs;
