import React, { Component, ReactNode } from "react";
import PageRouter from "./routes/PageRouter";
import "./styles/styles.scss";
import "./App.scss";
class App extends Component<any> {
  render(): ReactNode {
    return <PageRouter />;
  }
}

export default App;
