import React, { Component } from "react";
import { withRouter } from "react-router-dom";

interface ScrollToTopProps {
  location: any;
  history: any;
  match: any;
}

class ScrollToTop extends Component<ScrollToTopProps> {
  componentDidUpdate(prevProps: any) {
    const { location } = this.props;

    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return <></>;
  }
}

export default withRouter(ScrollToTop);
