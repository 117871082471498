import React, { ReactElement, ReactNode, useState, useEffect } from "react";
import { Footer, Header, Maybe } from "@presentational";

interface LayoutsProps {
  headerColor?: "white";
  footerVisible?: boolean;
  children: ReactElement | ReactNode;
}

const Layouts = ({ children, headerColor, footerVisible }: LayoutsProps) => {
  const [isShow, setIsShow] = useState(false);

  const handleScroll = (): void => {
    const { pageYOffset } = window;
    const deltaY = pageYOffset - 400;

    if (pageYOffset !== 0 && deltaY >= 0) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  };

  useEffect(() => {
    return () => window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      <Header headerColor={headerColor} />
      <div id="content">{children}</div>
      <Maybe test={footerVisible} children={<Footer />} />
      <a onClick={handleScroll} className="top-button" href="#content">
        <i className={`icon-top ${isShow ? "active" : ""}`} />
      </a>
    </div>
  );
};

export default Layouts;
