import MedicalInfoPage from "../pages/medicalInfo/MedicalInfoPage";
import MedicalInfoContents from "../pages/medicalInfo/MedicalInfoContents";

const medicalInfoConfigs = [
  /**
   * 최신 치료 동향
   */
  {
    path: "/medicalinfo/list",
    title: "최신 치료 동향",
    component: MedicalInfoPage,
  },
  {
    path: "/medicalinfo/info/:id",
    title: "최신 치료 동향",
    component: MedicalInfoContents,
  },
];

export default medicalInfoConfigs;
