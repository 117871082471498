import SkinInfoPage from "../pages/skinInfo/SkinInfoPage";

const skinInfoConfigs = [
  /**
   * 피부 질환
   */
  {
    path: "/skininfo",
    title: "피부 의학 정보",
    component: SkinInfoPage,
  },
];

export default skinInfoConfigs;
