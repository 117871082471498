import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";

export type FooterProps = {
  footerVisible?: true;
};

const Footer = ({ footerVisible }: FooterProps) => {
  return (
    <footer className="footer">
      <div className="footer-inner">
        <div className="footer-info__contents">
          <h1 className="footer-logo">
            <Link to="/" className="footer-logo__link">
              AI2U
            </Link>
          </h1>

          <div className="footer-address__contents">
            <address className="footer-address">
              회사명 : (주) 에이아이투유&nbsp; | &nbsp;대표자 : 손상욱
              <br />
              사업자 등록 번호 : 125-86-33065 <br /> 주소 : 서울특별시 성북구
              고려대로 73 고려대학교 의과대학 본관 7층 <br />
              대표전화 : 031-412-6730
            </address>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
