import React, { ReactNode } from "react";
import { Layouts, Map } from "@container";

const ContactPage = (): ReactNode => {
  return (
    <Layouts>
      <section className="section-header contact-header">
        <h2>CONTACT</h2>
      </section>
      <section className="contact-container">
        <div className="contents-inner">
          <h2 className="contact-title">오시는 길</h2>
          <div className="contact-info">
            <p className="contact-info__address">
              서울특별시 성북구 고려대로 73, 7층 피부과 실험실 (안암동 5가,
              고려대학교 의과대학 본관내)
            </p>
            <p className="contact-info__number">
              대표번호 (02)000-0000 | 팩스 (02)000-0000
            </p>
          </div>
        </div>
        <div className="contact-map">
          <Map />
        </div>
      </section>
    </Layouts>
  );
};

export default ContactPage;
