import React, { ReactNode, useState } from "react";
import shortid from "shortid";
import { Layouts } from "@container";
import SkinInfoContents from "./SkinInfoContents";
import { mainSkinInfoList } from "../main/configs";

const SkinInfoPage = ({ location }): ReactNode => {
  const pathname = location.pathname;
  const [isShow, setIsShow] = useState(false);

  const onClickShowTab = () => {
    setIsShow(!isShow);
  };

  const handleMouseLeave = () => {
    setIsShow(false);
  };

  return (
    <Layouts>
      <section className={`skin-container ${isShow ? "active" : ""}`}>
        <div className="skin-tab__wrap" onMouseLeave={handleMouseLeave}>
          <div className="skin-tab__inner">
            <div className="skin-tab__mobile">
              <button
                type="button"
                className="skin-tab__mobile--btn"
                onClick={onClickShowTab}
              >
                <span>피부 의학 정보</span>
                <i className="icon-expand" />
              </button>
            </div>
          </div>

          <div className="skin-tab__contents">
            {mainSkinInfoList.map((item) => (
              <div className="skin-tab__button--wrap" key={shortid.generate()}>
                <a
                  className={`skin-tab__button ${
                    pathname === item.url ? "skin-tab__button--active" : ""
                  }`}
                  href={item.url}
                >
                  {item.title}
                </a>
              </div>
            ))}
          </div>
          <div className="skin-tab__mobile--wrap">
            <div
              className={`skin-tab__contents--mobile ${isShow ? "active" : ""}`}
            >
              {mainSkinInfoList.map((item) => (
                <div
                  className="skin-tab__button--wrap"
                  key={shortid.generate()}
                >
                  <a
                    className={`skin-tab__button ${
                      pathname === item.url ? "skin-tab__button--active" : ""
                    }`}
                    href={item.url}
                    onClick={handleMouseLeave}
                  >
                    {item.title}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>

        {pathname && <SkinInfoContents />}
      </section>
    </Layouts>
  );
};

export default SkinInfoPage;
