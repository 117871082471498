import React, { ReactElement, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import shortid from "shortid";
import Slider from "react-slick";
import {
  Info01,
  Info02,
  Info03,
  Info04,
  Info05,
  Info06,
  Info07,
  Info08,
  Info09,
  Info10,
  Info11,
  Info12,
} from "./Info";
import { mainSkinInfoList } from "../main/configs";

const SkinInfoContents = ({ location }): ReactElement => {
  const pathname = location.pathname;

  const slider = {
    dots: true,
    infinite: true,
    centerPadding: "0",
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
  };

  const [showItem1, setShowItem1] = useState(false);
  const [showItem2, setShowItem2] = useState(false);
  const [showItem3, setShowItem3] = useState(false);
  const [showItem4, setShowItem4] = useState(false);
  const [showItem5, setShowItem5] = useState(false);
  const [showItem6, setShowItem6] = useState(false);
  const [showItem7, setShowItem7] = useState(false);
  const [showItem8, setShowItem8] = useState(false);
  const [showItem9, setShowItem9] = useState(false);
  const [showItem10, setShowItem10] = useState(false);
  const [showItem11, setShowItem11] = useState(false);
  const [showItem12, setShowItem12] = useState(false);

  const renderSkinInfoItems = () => {
    if (pathname === "/skininfo/1") {
      setShowItem1(true);
    } else {
      setShowItem1(false);
    }
    if (pathname === "/skininfo/2") {
      setShowItem2(true);
    } else {
      setShowItem2(false);
    }
    if (pathname === "/skininfo/3") {
      setShowItem3(true);
    } else {
      setShowItem3(false);
    }
    if (pathname === "/skininfo/4") {
      setShowItem4(true);
    } else {
      setShowItem4(false);
    }
    if (pathname === "/skininfo/5") {
      setShowItem5(true);
    } else {
      setShowItem5(false);
    }
    if (pathname === "/skininfo/6") {
      setShowItem6(true);
    } else {
      setShowItem6(false);
    }
    if (pathname === "/skininfo/7") {
      setShowItem7(true);
    } else {
      setShowItem7(false);
    }
    if (pathname === "/skininfo/8") {
      setShowItem8(true);
    } else {
      setShowItem8(false);
    }
    if (pathname === "/skininfo/9") {
      setShowItem9(true);
    } else {
      setShowItem9(false);
    }
    if (pathname === "/skininfo/10") {
      setShowItem10(true);
    } else {
      setShowItem10(false);
    }
    if (pathname === "/skininfo/11") {
      setShowItem11(true);
    } else {
      setShowItem11(false);
    }
    if (pathname === "/skininfo/12") {
      setShowItem12(true);
    } else {
      setShowItem12(false);
    }
  };

  useEffect(() => {
    renderSkinInfoItems();
  });

  return (
    <section className="skin-contents__wrap">
      {(showItem1 && <Info01 />) ||
        (showItem2 && <Info02 />) ||
        (showItem3 && <Info03 />) ||
        (showItem4 && <Info04 />) ||
        (showItem5 && <Info05 />) ||
        (showItem6 && <Info06 />) ||
        (showItem7 && <Info07 />) ||
        (showItem8 && <Info08 />) ||
        (showItem9 && <Info09 />) ||
        (showItem10 && <Info10 />) ||
        (showItem11 && <Info11 />) ||
        (showItem12 && <Info12 />)}

      {/** slider  */}
      <section className="skin-contents__slider--area">
        <div className="skin-contents__slider--inner">
          <div className="skin-contents__slider--title">
            <h3>다른 질환 알아보기</h3>
          </div>

          <Slider {...slider} className="skin-contents__slider">
            {mainSkinInfoList.map((item) => (
              <div
                className={`${
                  pathname === item.url
                    ? "slick-slide slick-active slick-current"
                    : ""
                }`}
                key={shortid.generate()}
              >
                <div>
                  <div className="skin-contents__slider--list">
                    <a href={item.url}>
                      <div className="skin-contents__slider--item">
                        <p
                          className={`${
                            pathname === item.url ? "title-active" : ""
                          }`}
                        >
                          {item.title}
                        </p>
                        <span
                          className={`${
                            pathname === item.url ? "info-active" : ""
                          }`}
                        >
                          {item.info}
                        </span>
                      </div>
                      <p className="skin-contents__slider--hover">
                        증상 및 치료법
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </section>
    </section>
  );
};

export default withRouter(SkinInfoContents);
