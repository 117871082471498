export const navigationConfigs = [
  {
    id: 0,
    title: "최신 치료 동향",
    type: "item",
    url: "/medicalinfo/list",
  },
  {
    id: 1,
    title: "피부 의학 정보",
    type: "group",
    url: "/skininfo/1",
    children: [
      {
        id: 1,
        title: "접촉성 피부염",
        url: "/skininfo/1",
      },
      {
        id: 2,
        title: "손발톱백선",
        url: "/skininfo/2",
      },
      {
        id: 3,
        title: "백반증",
        url: "/skininfo/3",
      },
      {
        id: 4,
        title: "바이러스사마귀",
        url: "/skininfo/4",
      },
      {
        id: 5,
        title: "아토피 피부염",
        url: "/skininfo/5",
      },
      {
        id: 6,
        title: "지루피부염",
        url: "/skininfo/6",
      },
      {
        id: 7,
        title: "건선",
        url: "/skininfo/7",
      },
      {
        id: 8,
        title: "여드름",
        url: "/skininfo/8",
      },
      {
        id: 9,
        title: "대상포진",
        url: "/skininfo/9",
      },
      {
        id: 10,
        title: "두드러기",
        url: "/skininfo/10",
      },
      {
        id: 11,
        title: "기미",
        url: "/skininfo/11",
      },
      {
        id: 12,
        title: "탈모",
        url: "/skininfo/12",
      },
    ],
  },
  {
    id: 2,
    title: "서비스",
    type: "item",
    url: "/",
  },
  // {
  //   id: 3,
  //   title: "CONTACT",
  //   type: "item",
  //   url: "/contact",
  // },
];
