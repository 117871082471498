import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { navigationConfigs } from "@configs";
import "./Header.scss";

export type HeaderProps = {
  headerColor?: "white";
};

const Header = ({ headerColor }: HeaderProps) => {
  const [isActived, setIsActived] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isScolled, setIsScrolled] = useState(false);
  const [isBurger, setIsBurger] = useState(false);
  const [activeIndex] = useState(undefined);

  const handleScroll = (): void => {
    const { pageYOffset } = window;
    const deltaY = pageYOffset - 50;

    if (pageYOffset !== 0 && deltaY >= 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const updateLayout = (): void => {
    if (window.innerWidth < 1280) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  // const handleClickMobileMenu = (e: any, index: any): void => {
  //   e.stopPropagation();

  //   if (!isMobile) return;

  //   e.preventDefault();

  //   if (activeIndex === index) {
  //     setActiveIndex(undefined);
  //   } else {
  //     setActiveIndex(index);
  //   }
  // };

  const handleMouseEnter = (e): void => {
    if (isMobile) {
      e.preventDefault();
      return;
    }
    setIsActived(true);
  };

  const handleMouseLeave = (e): void => {
    if (isMobile) {
      e.preventDefault();
      return;
    }
    setIsActived(false);
  };

  const handleToggleBurger = (): void => {
    setIsBurger(!isBurger);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", updateLayout);
    return () => {
      updateLayout();
    };
  }, []);

  return (
    <header
      className={`header${
        headerColor === "white" ? " header-white" : " header-color"
      }${isScolled ? " header-scroll" : ""}${isBurger ? " header-mobile" : ""}${
        isActived ? " header-active" : ""
      }`}
    >
      <div className="header__container">
        <div className="header__align">
          {/** logo */}
          <h1 className="header-logo">
            <Link to="/" className="header-logo__link">
              AI2U
            </Link>
          </h1>

          {/** nav */}
          <nav
            className="nav"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {navigationConfigs.map((item, index) => (
              <ul key={item.id} className="nav-depth1">
                <li className="nav-depth1__item">
                  <a
                    href={item.url}
                    className={`nav-depth1__link${
                      isMobile && activeIndex === index
                        ? `nav-depth1__link--open`
                        : ""
                    }`}
                  >
                    <span className="nav-depth1__title">{item.title}</span>
                  </a>
                </li>
              </ul>
            ))}
          </nav>

          {/** mobile hamburger menu */}
          <button
            type="button"
            className="header-btn__trigger"
            onClick={handleToggleBurger}
          >
            <span className="header-btn__burger header-btn__burger--top">
              메뉴
            </span>
            <span className="header-btn__burger header-btn__burger--middle">
              열기
            </span>
            <span className="header-btn__burger header-btn__burger--bottom">
              닫기
            </span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
