import React, { Component, ReactNode } from "react";
import { withRouter } from "react-router-dom";
import "./Map.scss";

declare global {
  interface Window {
    kakao: any;
  }
}
const { kakao } = window;

class Map extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  componentDidMount = (): void => {
    const container = document.getElementById("map");
    const options = {
      center: new kakao.maps.LatLng(37.31932099093576, 126.82524364029143),
      level: 5,
    };
    const map = new kakao.maps.Map(container, options);

    const markerPosition = new kakao.maps.LatLng(
      37.58727963054044,
      127.026583113308
    );
    const marker = new kakao.maps.Marker({
      position: markerPosition,
    });

    marker.setMap(map);
  };

  render(): ReactNode {
    return <div id="map" />;
  }
}

export default withRouter(Map);
