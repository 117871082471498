import qs from "querystring";
import RestService from "../RestService";
class HospitalService {
  getHospitalDatas = async (conditions: any): Promise<void> => {
    const url = `/getHospBasisList1?serviceKey=4Dkh8bJezsdEEAF71ceXEBm%2Btt6WbVPlnTCX9vcqAi7PZ0uhajEic6W%2F%2BX3qwVLvLt8lISl9455%2FXdIGEfb3CQ%3D%3D&${qs.stringify(
      conditions
    )}`;

    try {
      const data = await RestService.get(url);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default new HospitalService();
