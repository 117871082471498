import React, { ReactElement } from "react";
import shortid from "shortid";
import { InfoSlider } from "@presentational";
import {
  info10,
  info10Cause,
  info10Cure,
  info10Symptom,
  info10Rule,
  info10Tab,
  info10Source,
  info10InfoResource,
  info10CureResource,
  info10CareResource,
} from "../configs";

const Info10 = (): ReactElement => {
  function SliderPaging(i) {
    const pageCount = document.querySelector(".slick-dots")?.childElementCount;
    return (
      <p>
        <span>{i + 1} </span>/ {pageCount}
      </p>
    );
  }

  return (
    <div className="skin-contents__inner">
      <div className="skin-contents__image--info">
        <h2 className="title">두드러기란?</h2>

        {/** top */}
        <div className="skin-contents__image--top">
          {info10.map((item) => (
            <div
              className="image-contents title-contents"
              key={shortid.generate()}
            >
              <div className="title-contents__icon">
                <i className={`icon ${item.className}`} />
              </div>
              <strong className="info">{item.title}</strong>
              {item.info.split("\n").map((text) => (
                <p className="sub-info" key={shortid.generate()}>
                  {text}
                </p>
              ))}
            </div>
          ))}
        </div>

        {/** cause */}
        <div className="skin-contents__image--cause">
          <h2 className="title">원인</h2>
          <div className="cause-inner">
            {info10Cause.map((item) => (
              <div
                className="image-contents cause-contents"
                key={shortid.generate()}
              >
                <div className="cause-contents__icon">
                  <i className={`icon ${item.className}`} />
                </div>
                <strong className="info">{item.title}</strong>
                {item.info.split("\n").map((text) => (
                  <p className="sub-info" key={shortid.generate()}>
                    {text}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>

        {/** symptom */}
        <div className="skin-contents__image--symptom">
          <h2 className="title">증상</h2>
          <div className="image-text__inner">
            {info10Symptom.map((item) => (
              <div className="image-text" key={shortid.generate()}>
                <strong className="info">{item.title}</strong>
                <p className="sub-info">{item.info}</p>
              </div>
            ))}
          </div>
        </div>

        {/** cure */}
        <div className="skin-contents__image--cure">
          <h2 className="title">진단과 치료</h2>
          <div className="cure-bg" />
          <div className="image-text__inner">
            {info10Cure.map((item) => (
              <div className="image-text" key={shortid.generate()}>
                <strong className="info">{item.title}</strong>
                {item.info.split("\n").map((text) => (
                  <p className="sub-info" key={shortid.generate()}>
                    {text} <br />
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>

        {/** rule */}
        <div className="skin-contents__image--rule">
          <h2 className="title">생활수칙</h2>
          <div className="rule-bg" />
          <div className="image-text__inner">
            {info10Rule.map((item) => (
              <div className="rule-text" key={shortid.generate()}>
                {item.info.split("\n").map((text) => (
                  <p className="sub-info" key={shortid.generate()}>
                    {text}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>

        {/** resource skin info */}
        <div className="skin-contents__image--resource">
          <h2 className="title">만성 두드러기의 이해와 관리</h2>
          <div className="image-text__inner">
            <InfoSlider
              showCount={1}
              scrollCount={1}
              paging={SliderPaging}
              tabList={info10Tab}
              slider1={info10InfoResource}
              slider2={info10CureResource}
              slider3={info10CareResource}
              source={info10Source}
              className="urticaria"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info10;
