import React, { ReactElement } from "react";
import ReactPlayer from "react-player";

const Info04 = (): ReactElement => {
  return (
    <>
      <div className="medical-contents__inner">
        <div className="medical-info__contents">
          <div className="medical-contents__item--text">
            <h2>아토피피부염 질환기전</h2>
            <p>
              <span className="date">2021.9.10</span>
              <span>#아토피피부염 #만성면역질환 #가려움증 #원인</span>
            </p>
          </div>

          <div className="medical-info__video">
            <ReactPlayer
              url="/assets/videos/video04.mp4"
              width="100%"
              height="100%"
              controls
              playing
              className="medical-info__video--item"
            />
          </div>

          <div className="medical-info__detail">
            <p>
              아토피피부염은 복합적인 유전적, 환경적 원인으로 면역 체계에 이상이
              생기면서 피부 깊은 곳에 있는 기저 염증이 신체 여러 부위에서
              발생하는 만성 면역질환입니다. 적절히 치료되지 않으면
              아토피피부염이 지속되어 만성적으로 되거나, 질병 부담이 축적되어
              삶의 전반에 걸쳐 막대한 영향을 줄 수 있습니다.
              <br />
              <br />
              아토피피부염의 주요 원인으로는, 감수성 유전자와 같은 유전적 요인,
              알러지 유발 항원, 미생물, 스트레스와 같은 환경적 요인, 제 2형 만성
              염증반응 및 피부 장벽 이상과 같은 면역 반응 요인이 있습니다.
              치료에도 불구하고 기저 염증으로 인해 만성 면역질환의 형태로
              나타나며, 환자들은 1년 중 30~50%의 시간동안 지속되는 질환 악화를
              경험합니다. 따라서, 아토피피부염 환자들의 장기적인 증상 조절을
              위해서는 기저 염증이 지속적으로 조절되어야 하며, 중증도에 따라
              국소적 치료와 전신적 치료를 통한 단계적인 치료가 필요합니다.
            </p>

            <a href="/skininfo/5" className="move-skininfo">
              아토피피부염 더 알아보기
            </a>
            <span>출처: Sanofi</span>
          </div>
        </div>
      </div>
      <div className="medical-contents__more">
        <p>
          <span>이전글이 없습니다</span>
          <a href="/medicalinfo/list" className="list">
            <i className="icon icon-list" />
            목록보기
          </a>
          <a href="/medicalinfo/info/5" className="next">
            다음 글
          </a>
        </p>
      </div>
    </>
  );
};

export default Info04;
