import React, { ReactNode } from "react";
import { Layouts } from "@container";

const CompanyPage = (): ReactNode => {
  return (
    <Layouts footerVisible={true}>
      <section className="section-header company-header">
        <h2>SERVICE</h2>
      </section>
      <section className="company-container">
        <div className="contents-inner">
          <h3 className="company-title">Comming Soon</h3>
        </div>
      </section>
    </Layouts>
  );
};

export default CompanyPage;
