import React, { ReactElement } from "react";
import shortid from "shortid";
import {
  info11,
  info11Cause,
  info11Cure,
  info11Symptom,
  info11Rule,
} from "../configs";

const Info11 = (): ReactElement => {
  return (
    <div className="skin-contents__inner">
      <div className="skin-contents__image--info">
        <h2 className="title">기미란?</h2>

        {/** top */}
        <div className="skin-contents__image--top">
          {info11.map((item) => (
            <div
              className="image-contents title-contents"
              key={shortid.generate()}
            >
              <div className="title-contents__icon">
                <i className={`icon ${item.className}`} />
              </div>
              <strong className="info">{item.title}</strong>
              {item.info.split("\n").map((text) => (
                <p className="sub-info" key={shortid.generate()}>
                  {text}
                </p>
              ))}
            </div>
          ))}
        </div>

        {/** cause */}
        <div className="skin-contents__image--cause">
          <h2 className="title">원인</h2>
          <div className="cause-inner">
            {info11Cause.map((item) => (
              <div
                className="image-contents cause-contents"
                key={shortid.generate()}
              >
                <div className="cause-contents__icon">
                  <i className={`icon ${item.className}`} />
                </div>
                <strong className="info">{item.title}</strong>
                {item.info.split("\n").map((text) => (
                  <p className="sub-info" key={shortid.generate()}>
                    {text}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>

        {/** symptom */}
        <div className="skin-contents__image--symptom">
          <h2 className="title">증상</h2>
          <div className="image-text__inner">
            {info11Symptom.map((item) => (
              <div className="image-text" key={shortid.generate()}>
                <strong className="info">{item.title}</strong>
                <p className="sub-info">{item.info}</p>
              </div>
            ))}
          </div>
        </div>

        {/** cure */}
        <div className="skin-contents__image--cure">
          <h2 className="title">진단과 치료</h2>
          <div className="cure-bg" />
          <div className="image-text__inner">
            {info11Cure.map((item) => (
              <div className="image-text" key={shortid.generate()}>
                <strong className="info">{item.title}</strong>
                <p className="sub-info">{item.info}</p>
              </div>
            ))}
          </div>
        </div>

        {/** rule */}
        <div className="skin-contents__image--rule">
          <h2 className="title">생활수칙</h2>
          <div className="rule-bg" />
          <div className="image-text__inner">
            {info11Rule.map((item) => (
              <div className="rule-text" key={shortid.generate()}>
                {item.info.split("\n").map((text) => (
                  <p className="sub-info" key={shortid.generate()}>
                    {text}
                  </p>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info11;
